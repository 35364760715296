import type { MutableRefObject } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

type UseDismissiblePopoverProps = {
  /**
   * Should the popover be open by default or not
   */
  defaultOpen?: boolean;
  /**
   * Number of milliseconds the popover should stay visible
   */
  closeAfterMs?: number;
  /**
   * Optional method to be executed when the popover closes
   *
   * @returns void
   */
  onClose?: () => void;
  /**
   * The ref for the popover, to check that a click happens outside of the popover
   */
  popoverRef: MutableRefObject<HTMLElement | null>;
};

/**
 *
 * @param props UseDismissiblePopverProps
 * @returns open state
 */
export const useDismissiblePopover = (
  props: UseDismissiblePopoverProps,
): [boolean, () => void] => {
  const {
    defaultOpen = true,
    closeAfterMs = 10_000,
    onClose,
    popoverRef,
  } = props;
  const wasOpen = useRef<boolean>(false);
  const [isOpen, setOpen] = useState<boolean>(false);

  const dismissTimer = useRef<number | undefined>(undefined);

  const popoverMethods = useMemo(() => {
    function clickOutsideListener(event: MouseEvent) {
      const target = event.target as HTMLElement;
      // If we have a target, and a popover ref and the target is neither the popoverRef nor a descendant of the popoverRef
      if (
        target &&
        popoverRef?.current &&
        popoverRef.current !== target &&
        !popoverRef.current.contains(target)
      ) {
        closePopup();
      }
    }
    function escapeKeyListener(event: KeyboardEvent) {
      if (event.key === 'Escape' || event.key === 'Esc') {
        closePopup();
      }
    }

    // `unmount` check is for the double-run of effects in StrictMode
    function closePopup(unmount = false) {
      setOpen(false);
      wasOpen.current = false;

      window.removeEventListener('click', clickOutsideListener);
      document.removeEventListener('keyup', escapeKeyListener);

      window.clearTimeout(dismissTimer.current);

      if (!unmount) {
        onClose?.();
      }
    }

    function popupListeners() {
      window.addEventListener('click', clickOutsideListener);
      document.addEventListener('keyup', escapeKeyListener);

      dismissTimer.current = window.setTimeout(() => {
        closePopup();
      }, closeAfterMs);
    }
    return {
      clickOutsideListener,
      escapeKeyListener,
      closePopup,
      popupListeners,
    };
  }, [popoverRef, onClose, closeAfterMs]);

  useEffect(() => {
    setOpen(defaultOpen);
    wasOpen.current = defaultOpen;

    if (closeAfterMs && defaultOpen) {
      popoverMethods.popupListeners();
    }

    return () => {
      popoverMethods.closePopup(true);
    };
  }, [closeAfterMs, defaultOpen, popoverMethods]);

  /**
   * A callback to open the popover programmatically and add the appropriate
   * event listeners
   */
  const openPopup = useCallback(() => {
    // If the
    if (!isOpen && wasOpen.current === isOpen) {
      wasOpen.current = !isOpen;
      setOpen(!isOpen);

      popoverMethods.popupListeners();
    }
  }, [isOpen, popoverMethods]);

  return useMemo(() => [isOpen, openPopup], [isOpen, openPopup]);
};
